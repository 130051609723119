<!--  -->
<template>
  <div class="notice-body">
    <p class="title">全部订单</p>
    <ul class="notice-list">
      <li>
        <!-- <div class="topoder">
          <p style="width: 540px">订单详情</p>
          <p style="width: 153px">金额</p>
          <p style="width: 169px">支付方式</p>
          <p style="width: 153px">订单状态</p>
        </div> -->
        <el-row class="topoder">
          <el-col :span="13">
            <span>订单详情</span>
          </el-col>
          <el-col :span="3">
            <span>金额</span>
          </el-col>
          <el-col :span="4">
            <span>支付方式</span>
          </el-col>
          <el-col :span="4">
            <span>订单状态</span>
          </el-col>
        </el-row>
      </li>
      <li v-for="(item, index) in orderList" :key="index">
        <div class="oderbox">
          <div class="oderbox-top">
            <p style="width: 25%">下单时间: {{ item.createTime }}</p>
            <p style="width: 233px">订单号：{{ item.orderNumber }}</p>
            <p style="width: 233px" v-if="item.payTime">
              支付时间：{{ item.payTime }}
            </p>
          </div>
          <div
            v-for="(orderItemDtos, orderItemDtosindex) in item.orderItemDtos"
            class="oderbox-content"
            :key="orderItemDtosindex"
          >
            <el-row v-if="item.orderItemDtos.length == 1">
              <el-col :span="13">
                <div
                  @click="viewdetail(item, index)"
                  class="grid-content bg-purple"
                  style="padding: 20px 18px; display: flex"
                >
                  <img
                    style="width: 80px; height: 80px"
                    :src="orderItemDtos.pic"
                    alt=""
                  />
                  <div class="change-blue">
                    <p class="content-title">
                      {{ orderItemDtos.prodName }}
                    </p>
                    <p class="content-content">{{ orderItemDtos.skuName }}</p>
                    <p class="content-footer">
                      <a
                        v-show="itemproperties"
                        v-for="itemproperties in (
                          orderItemDtos.properties || ''
                        ).split(';')"
                        :key="itemproperties"
                        >{{ itemproperties }}&nbsp;&nbsp;</a
                      ><a>商品数量：{{ orderItemDtos.prodCount }} </a>
                      <a v-if="item.referrer">推荐人: {{ item.referrer }}</a>
                      <a v-if="item.takeRemark">备注：{{ item.takeRemark }}</a>
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple">
                  <p>¥{{ item.actualTotal }}</p>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <p v-if="item.payTypeLabel">{{ item.payTypeLabel }}</p>
                  <p v-else>未支付</p>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <p>
                    {{ options[item.status] }} &nbsp;&nbsp;
                    <button
                      v-if="item.status == 1"
                      class="pay"
                      @click="pay(item, index)"
                    >
                      继续支付
                    </button>
                  </p>
                </div>
              </el-col>
            </el-row>

            <el-row v-else>
              <el-col :span="13">
                <div
                  @click="viewdetail(item, index)"
                  class=""
                  style="padding: 14px 18px; display: flex"
                >
                  <img
                    style="width: 80px; height: 80px"
                    :src="orderItemDtos.pic"
                    alt=""
                  />
                  <div class="change-blue">
                    <p class="content-title">
                      {{ orderItemDtos.prodName }}
                    </p>
                    <p class="content-content">{{ orderItemDtos.skuName }}</p>
                    <p class="content-footer">
                      <a
                        v-show="itemproperties"
                        v-for="itemproperties in (
                          orderItemDtos.properties || ''
                        ).split(';')"
                        :key="itemproperties"
                        >{{ itemproperties }}&nbsp;&nbsp;</a
                      ><a>商品数量：{{ orderItemDtos.prodCount }} </a>
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple">
                  <p v-if="orderItemDtosindex == 0">¥{{ item.actualTotal }}</p>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <div class="grid-content" v-if="orderItemDtosindex == 0">
                    <p v-if="item.payTypeLabel">{{ item.payTypeLabel }}</p>
                    <p v-else>未支付</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <p v-if="orderItemDtosindex == 0">
                    {{ options[item.status] }} &nbsp;&nbsp;
                    <button
                      v-if="item.status == 1"
                      class="pay"
                      @click="pay(item, index)"
                    >
                      继续支付
                    </button>
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </li>
    </ul>
    <el-pagination
      style="text-align: center"
      background
      layout="prev, pager, next"
      :total="allcount"
      :current-page="page.current"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getMyorder,
  payOrder,
  getorderDetail,
  getMyorderCount,
  deleteOrder,
} from "@/api/all.js";
export default {
  data() {
    return {
      orderList: [],
      options: {
        "-2": "退款中",
        "-1": "已退款",
        1: "待付款",
        2: "待发货",
        3: "待收货",
        4: "待评价",
        5: "成功",
        6: "失败",
      },
      allcount: 10,
      page: {
        current: 1,
        size: 10,
        status: 0,
      },
    };
  },
  created() {
    //获取订单数量
    getMyorderCount().then((res) => {
      this.allcount = res.data.allCount;
    });
    //根据订单状态获取订单列表信息，状态为0时获取所有订单 订单状态 1:待付款 2:待发货 3:待收货 4:待评价 5:成功 6:失败
    getMyorder(this.page).then((res) => {
      this.orderList = res.data.records;
    });
    //获取订单数量
    // getMyorderCount().then((res) => {
    //   console.log(res);
    // })
  },
  methods: {
    deleteorder(item) {
      console.log(item);
      deleteOrder(item.orderNumber).then((res) => {
        console.log(res);
      });
    },
    viewdetail(item, index) {
      getorderDetail({ orderNumber: item.orderNumber }).then((res) => {
        this.$router.push({
          name: "buy",
          query: { id: res.data.orderItemDtos[0].prodId },
        });
      });
    },
    pay(item, index) {
      getorderDetail({ orderNumber: item.orderNumber }).then((res) => {
        console.log(res, "商品详情");
        this.$router.push({
          path: "/issue",
          query: {
            orderNumbers: item.orderNumber,
            prodId: res.data.orderItemDtos[0].prodId,
            totalCount: item.orderItemDtos[0].prodCount,
            actualTotal: res.data.total,
          },
        });
      });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      getMyorder(this.page).then((res) => {
        this.orderList = res.data.records;
      });
    },
  },
};
</script>

<style scoped>
.order-info {
}
.change-blue:hover {
  color: #fff;
}
.pay {
  font-size: 12px;
  width: 55px;
  height: 30px;
  background-color: #b60005;
  color: #ffffff;
  border-radius: 2px;
}
.content-footer {
  font-size: 12px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  padding: 0 0 8px 15px;
}

.content-footer > a {
  padding-right: 10px;
}
a {
  color: #666666;
}
.notice-list > li > div > p > a {
  color: #666666 !important;
}
a:hover {
  color: #666666 !important;
}
.content-content {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  padding: 0 0 15px 15px;
}
.content-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #141414;
  padding: 0 0 14px 15px;
}
.grid-content {
  height: 121px;
}
/*.oderbox-content {*/
/*  height: 121px;*/
/*}*/
.grid-content {
  height: 121px;
  border-left: 1px solid #e9e9e9;
}
.grid-content > p {
  text-align: center;
  line-height: 121px;
  height: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.oderbox-top > p > span {
  width: 77px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #141414 !important;
}
.oderbox-top > p {
  width: 118px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.oderbox-top {
  display: flex;
  justify-content: space-around;
  line-height: 36px;
  height: 36px;
  background: #f6f6f6;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.oderbox {
  width: 878px;
  /*height: 157px;*/
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #e9e9e9;
}
.title {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #141414;
}
.topoder {
  display: flex;
}
.topoder > .el-col {
  text-align: center;
}
.topoder > .el-col > span {
  height: 36px;
  line-height: 36px;
  width: 48px;
  height: 36px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.notice-list > li {
  padding: 11px 0;
  border-bottom: 0px;
}
.topoder {
  height: 36px;
  background: #f6f6f6;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
</style>
